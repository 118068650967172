let utils = {};

utils.getPageDataUsingId = (data, pageId) => {
  return data.map((val, id) => {
    return val.page_id == pageId ? val : "";
  });
};

utils.getImageUsingName = (imageArr, name) => {
  let imageUrl = "";
  if (imageArr) {
    imageArr.forEach((imageOb) => {
      if (imageOb.type == name) {
        imageUrl = imageOb.image;
      }
    });
  }
  return imageUrl;
};
export default utils;
