import React, { useContext } from "react";
import Styles from "./ContactUs.module.css";
import CommonHeader from "../CommonHeader/CommonHeader";
import dummy from "../../../assets/dummy.png";
import instagram_logo from "../../../assets/instagram_logo.svg";
import facebook_logo from "../../../assets/facebook_logo.svg";
import linkedIn_logo from "../../../assets/linkedIn_logo.svg";
import config from "../../../config";
import DataContext from "../DataContext";
import utils from "../../../utils";
function ContactUs(props) {
  let dataContext = useContext(DataContext);
  return (
    <div className={Styles.container}>
      <CommonHeader
        heading={"Contact"}
        bg={`${config.backendUrl}/images/${utils.getImageUsingName(
          dataContext?.pageImages,
          "contact_header"
        )}`}
      />
      <div className={Styles.body}>
        <div className={Styles.infoContainer}>
          {/* <div className={Styles.infoBox}>
            <p>reach us</p>
            <p>
              {" "}
              {dataContext &&
                dataContext?.pageText &&
                dataContext.pageText[7]?.content}
            </p>
          </div> */}
          <div className={Styles.infoBox}>
            <p>address</p>
            <p>
              {" "}
              {dataContext &&
                dataContext?.pageText &&
                dataContext.pageText[8]?.content}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <p>enquiry calls</p>
            <p>
              {" "}
              {dataContext &&
                dataContext?.pageText &&
                dataContext.pageText[10]?.content}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <p>email us</p>
            <p>
              {dataContext &&
                dataContext?.pageText &&
                dataContext.pageText[9]?.content}
            </p>
          </div>
          <div className={Styles.socialMediaContainer}>
            <img
              src={instagram_logo}
              alt="insta"
              onClick={() => {
                window.open(
                  "https://instagram.com/asiangraniteexpo?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr",
                  "_blank"
                );
              }}
            />
            <img
              src={facebook_logo}
              alt="fb"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/profile.php?id=61551345005963&mibextid=LQQJ4d",
                  "_blank"
                );
              }}
            />
            <img
              src={linkedIn_logo}
              alt="linkedin"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/asian-granite-expo/",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
        <div className={Styles.mapContainer}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14265.315093729905!2d74.8450912!3d26.6379539!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396bf349a1837e65%3A0x3c2eb2b3e6b6307f!2sAsian%20Granite%20Expo!5e0!3m2!1sen!2sin!4v1699159725712!5m2!1sen!2sin"
            // width="600"
            // height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
