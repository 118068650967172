import React, { useEffect, useState } from "react";
import Styles from "./Testimonials.module.css";
import Table from "../Table/Table";
import AlertBox from "../../AlertBox/AlertBox";
import api from "../../../Api";
import testimonial_icon from "../../../assets/testimonials.svg";
function Testimonials(props) {
  let [data, changeData] = useState([]);

  let [alertActive, changeActive] = useState({
    type: "",
    message: "",
  });

  useEffect(() => {
    fetchPageData();
  }, []);

  async function fetchPageData() {
    try {
      let result = await api.getTestimonials();
      changeData(result);
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  function hideAlert() {
    changeActive({
      type: "",
      message: "",
    });
  }

  function showAlert(type, message) {
    changeActive({
      type,
      message,
    });
  }
  return (
    <div className={Styles.container}>
      <AlertBox
        message={alertActive.message}
        type={alertActive.type}
        isActive={alertActive.message}
        changeActive={hideAlert}
      />
      <div className={Styles.headingBox}>
        <img src={testimonial_icon} alt="page" />
        <p className={Styles.heading}>Testimonials</p>
      </div>

      <div className={Styles.updateSection}>
        <Table
          data={data}
          type={"testimonials"}
          fetchPageData={fetchPageData}
          showAlert={showAlert}
        />
      </div>
    </div>
  );
}

export default Testimonials;
