import React, { useEffect } from "react";
import Styles from "./Modal.module.css";
import { useMediaQuery } from "react-responsive";
function Modal(props) {
  const smallScreen = useMediaQuery({ query: "(max-width:720px)" });
  useEffect(() => {
    function handler(e) {
      if (e?.target?.id && e.target.id == "modalContainer") {
        props.changeActive(false);
      }
    }
    // eslint-disable-next-line no-restricted-globals
    addEventListener("click", handler);
    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("click", handler);
    };
  });
  return (
    <div
      className={Styles.container}
      id="modalContainer"
      style={{ visibility: props.isActive ? "visible" : "hidden" }}
    >
      <div
        className={Styles.body}
        style={{
          transform: props.isActive
            ? smallScreen
              ? "scale(0.9)"
              : "scale(1)"
            : "scale(0.5)",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Modal;
