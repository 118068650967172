import React, { useState, useEffect, useContext } from "react";
import Styles from "./Products.module.css";
import dummy from "../../../assets/dummy.png";
import dummy_product from "../../../assets/dummy_product.png";
import Modal from "../Modal/Modal";
import CommonHeader from "../CommonHeader/CommonHeader";
import { useLocation } from "react-router-dom";
import api from "../../../Api";
import config from "../../../config";
import DataContext from "../DataContext";
import utils from "../../../utils";

import Slider from "react-slick";
function Products(props) {
  let dataContext = useContext(DataContext);
  let location = useLocation();
  let [isActive, changeActive] = useState(false);
  let [productItems, changeProductItems] = useState([]);
  let [currentProductData, changeProductData] = useState([]);
  // let [recentId, changeRecentId] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  async function getProductItems(id) {
    try {
      let result = await api.getProductItems(id);
      changeProductItems(result);
    } catch (err) {}
  }

  useEffect(() => {
    if (location && location?.search && location.search.search("id") > -1) {
      let searchId = location.search.split("id=")[1];
      getProductItems(searchId);
    }
  }, [location]);

  function productInfoModalBody(productData) {
    return (
      <div className={Styles.infoContainer}>
        <p className={Styles.infoHeading}>{currentProductData?.product_name}</p>
        <div className={Styles.infobody}>
          <div className={Styles.imageContainer}>
            <Slider {...settings}>
              <img
                alt="infoImg"
                src={`${config.backendUrl}/images/${currentProductData?.product_image}`}
              />
              {currentProductData?.images?.map((val, id) => {
                return val.image ? (
                  <img
                    alt="infoImg"
                    src={`${config.backendUrl}/images/${val.image}`}
                  />
                ) : null;
              })}
            </Slider>
          </div>

          <div className={Styles.infoContent}>
            <div className={Styles.infoBox}>
              <pre>{currentProductData?.product_description}</pre>
              {/* <p>Origin</p>
              <p>South India</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <Modal isActive={isActive} changeActive={changeActive}>
        {productInfoModalBody()}
      </Modal>
      <CommonHeader
        heading={"products"}
        bg={`${config.backendUrl}/images/${utils.getImageUsingName(
          dataContext?.pageImages,
          "products_header"
        )}`}
      />

      <div className={Styles.body}>
        <div className={Styles.showcaseContainer}>
          {productItems.map((val, ind) => {
            return (
              <div
                className={Styles.productBox}
                onClick={() => {
                  changeProductData(val);
                  changeActive(true);
                }}
              >
                <img
                  src={`${config.backendUrl}/images/${val?.product_image}`}
                  alt="prod"
                />
                <p>{val?.product_name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Products;
