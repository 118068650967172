import React, { useState, useEffect } from "react";
import Styles from "./Layout.module.css";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import DataContext from "./DataContext";
import api from "../../Api";
import Modal from "./Modal/Modal";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function Layout(props) {
  let [pageText, changePageText] = useState([]);
  let [productCategories, changeProductCategories] = useState([]);
  let [pageImages, changePageImages] = useState([]);
  let [isActive, changeActive] = useState(false);
  const [value, setValue] = useState("");
  let [alert, changeAlert] = useState({
    type: null,
    message: null,
  });

  useEffect(() => {
    if (isActive) {
      clearValues();
    }
  }, [isActive]);
  let handleText = async () => {
    try {
      let result = await api.getAllPageText();
      changePageText(result);
    } catch (err) {}
  };
  let handleProducts = async () => {
    try {
      let result = await api.getProductCategories();
      changeProductCategories(result);
    } catch (err) {}
  };
  let handleImages = async () => {
    try {
      let result = await api.getAllPageImages();
      changePageImages(result);
    } catch (err) {}
  };

  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        changeAlert({
          type: null,
          message: null,
        });
      }, 5000);
    }
  }, [alert.message]);

  function clearValues() {
    document.getElementById("quoteName").value = "";
    document.getElementById("quoteEmail").value = "";
    document.getElementById("quoteProduct").value = "";
    setValue("");
    // document.getElementById("quotePhone").value = "";
    document.getElementById("location").value = "";
    document.getElementById("quoteMessage").value = "";
  }
  async function handleQuote() {
    try {
      let name = document.getElementById("quoteName").value;
      let email = document.getElementById("quoteEmail").value;
      let product = document.getElementById("quoteProduct").value;
      // let phone = document.getElementById("quotePhone").value;
      let phone = value;
      let location = document.getElementById("location").value;
      let message = document.getElementById("quoteMessage").value;

      if (name && email && product && phone && location && message) {
        // const phoneRegex =
        //   /^(\+\d{1,2}\s?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
        // if (!phoneRegex.test(phone)) {
        //   throw "Invalid phone number";
        // }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
          throw "Invalid Email";
        }
        let result = await api.addQuote(
          name,
          email,
          product,
          phone,
          location,
          message
        );
        if (result?.success) {
          changeAlert({
            type: "success",
            message: "We have received your message, we'll get back to you",
          });
          clearValues();
          setTimeout(() => {
            changeActive(false);
          }, 3000);
        } else {
          throw "Something went wrong";
        }
      } else {
        throw "Invalid values";
      }
    } catch (err) {
      changeAlert({
        type: "error",
        message: err,
      });
    }
  }

  function getQuoteUi() {
    return (
      <div className={Styles.infoContainer}>
        <p className={Styles.infoHeading}>Get Quote</p>

        <div className={Styles.infobody}>
          {alert && alert?.message ? (
            <p
              className={Styles.alertMessage}
              style={{ color: alert.type == "error" ? "red" : "green" }}
            >
              {alert.message}
            </p>
          ) : (
            ""
          )}

          <input type="text" id="quoteName" placeholder="Name" required />
          <input type="email" id="quoteEmail" placeholder="Email" required />
          <select id="quoteProduct">
            <option value="">Select Product</option>
            {productCategories &&
              productCategories.map((val, id) => {
                return <option value={val.id}>{val.name}</option>;
              })}
          </select>
          <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={setValue}
          />
          {/* <input
            type="text"
            id="quotePhone"
            placeholder="Phone Number"
            required
          /> */}
          <input
            type="text"
            id="location"
            placeholder="Enter your location"
            required
          />
          <textarea id="quoteMessage" placeholder="Message"></textarea>

          <button onClick={handleQuote}>Submit</button>
        </div>
      </div>
    );
  }
  useEffect(() => {
    handleText();
    handleProducts();
    handleImages();
  }, []);

  return (
    <DataContext.Provider value={{ pageText, pageImages, productCategories }}>
      <div>
        <Modal isActive={isActive} changeActive={changeActive}>
          {getQuoteUi()}
        </Modal>
        <Header changeActive={changeActive} />
        <Outlet />
        <Footer />
      </div>
    </DataContext.Provider>
  );
}

export default Layout;
