import React, { useEffect, useState } from "react";
import Styles from "./Login.module.css";
import api from "../../../Api";
import AlertBox from "../../AlertBox/AlertBox";
import { useNavigate } from "react-router-dom";
function Login(props) {
  let navigate = useNavigate();
  let [alertActive, changeActive] = useState({
    type: "",
    message: "",
  });
  function hideAlert() {
    changeActive({
      type: "",
      message: "",
    });
  }

  function showAlert(type, message) {
    changeActive({
      type,
      message,
    });
  }

  async function loginHandler() {
    try {
      let username = document.getElementById("userName").value;
      let password = document.getElementById("userPassword").value;
      if (username && password) {
        let result = await api.login(username, password);
        if (result?.success) {
          let token = result.data.token;
          localStorage.setItem("token", token);
          navigate("/admin/page_text");
        } else {
          showAlert("danger", result.data);
        }
      } else {
        showAlert("danger", "Invalid values");
      }
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }
  return (
    <div className={Styles.container}>
      <AlertBox
        message={alertActive.message}
        type={alertActive.type}
        isActive={alertActive.message}
        changeActive={hideAlert}
      />
      <div className={Styles.inputContainer}>
        <p>Enter login details</p>
        <input type="text" id="userName" required placeholder="Username" />
        <input
          type="password"
          id="userPassword"
          required
          placeholder="Password"
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            loginHandler();
          }}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
