import React, { useContext } from "react";
import Styles from "./Infrastructor.module.css";
import CommonHeader from "../CommonHeader/CommonHeader";
import dummy from "../../../assets/dummy.png";
import DataContext from "../DataContext";
import config from "../../../config";
import utils from "../../../utils";
function Infrastructor(props) {
  let dataContext = useContext(DataContext);
  return (
    <div className={Styles.container}>
      <CommonHeader
        heading={"Infrastructure"}
        bg={`${config.backendUrl}/images/${utils.getImageUsingName(
          dataContext?.pageImages,
          "infrastructure_header"
        )}`}
      />
      <div className={Styles.body}>
        {dataContext &&
          dataContext?.pageImages &&
          dataContext.pageImages.map((val, id) => {
            return val.type == "infrastructure_main" ? (
              <img
                src={`${config.backendUrl}/images/${val?.image}`}
                alt="infra"
              />
            ) : (
              ""
            );
          })}
      </div>
    </div>
  );
}

export default Infrastructor;
