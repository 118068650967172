import React, { useContext } from "react";
import Styles from "./AboutUs.module.css";
import CommonHeader from "../CommonHeader/CommonHeader";
import dummy from "../../../assets/dummy.png";
import DataContext from "../DataContext";
import config from "../../../config";
import utils from "../../../utils";
function AboutUs(props) {
  let dataContext = useContext(DataContext);
  return (
    <div className={Styles.container}>
      <CommonHeader
        heading={"about"}
        bg={`${config.backendUrl}/images/${utils.getImageUsingName(
          dataContext?.pageImages,
          "about_header"
        )}`}
      />
      <div className={Styles.body}>
        <div className={Styles.infoContainer}>
          <img
            src={`${config.backendUrl}/images/${utils.getImageUsingName(
              dataContext?.pageImages,
              "about_section"
            )}`}
            alt="img"
          />
          <div className={Styles.info}>
            <p>about us</p>
            <pre>
              {dataContext &&
                dataContext?.pageText &&
                dataContext.pageText[5]?.content}
            </pre>
          </div>
        </div>

        {/* <div className={Styles.teamInfoContainer}>
          <p>our team</p>
          <div className={Styles.teamInfoBox}>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>

            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
            <div className={Styles.teamInfo}>
              <img src={dummy} alt="team" />
              <div className={Styles.teamText}>
                <p>Name</p>
                <p>designation</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AboutUs;
