import React, { useState, useEffect } from "react";
import Styles from "../Products/Products.module.css";
import api from "../../../Api";
import AlertBox from "../../AlertBox/AlertBox";
import product_icon from "../../../assets/product_icon.svg";
import Table from "../Table/Table";
function Infrastructure(props) {
  let [pages, changePagesData] = useState([]);
  let [productCategories, changeProductCategories] = useState([]);
  let [currentPageId, changeCurrentPageId] = useState(null);
  let [alertActive, changeActive] = useState({
    type: "",
    message: "",
  });

  async function getPages() {
    try {
      let pageData = await api.getPages();
      changePagesData(pageData);
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  useEffect(() => {
    fetchPageData();
  }, []);

  async function fetchPageData() {
    try {
      let result = await api.getInfrastructureImages();
      if (result?.success) {
        changeProductCategories(result.data);
      } else {
        showAlert("danger", "Something went wrong");
      }
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  function hideAlert() {
    changeActive({
      type: "",
      message: "",
    });
  }

  function showAlert(type, message) {
    changeActive({
      type,
      message,
    });
  }
  return (
    <div className={Styles.container}>
      <AlertBox
        message={alertActive.message}
        type={alertActive.type}
        isActive={alertActive.message}
        changeActive={hideAlert}
      />
      <div className={Styles.headingBox}>
        <img src={product_icon} alt="page" />
        <p className={Styles.heading}>Infrastructure</p>
      </div>
      {/* <div className={Styles.selectBox}>
        <p>Select Page:</p>
        <select
          onChange={(e) => {
            changeCurrentPageId(e.target.value);
            fetchPageData(e.target.value);
          }}
        >
          <option value="">Select</option>
          {pages &&
            pages.map((val, id) => {
              return (
                <option value={val.id} key={id}>
                  {val.page_name}
                </option>
              );
            })}
        </select>
      </div> */}
      <div className={Styles.updateSection}>
        <Table
          data={productCategories}
          type={"infrastructure"}
          currentPageId={6}
          fetchPageData={fetchPageData}
          showAlert={showAlert}
        />
      </div>
    </div>
  );
}

export default Infrastructure;
