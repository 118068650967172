import "./App.css";
import Home from "./Components/User_panel/Home/Home";
import Products from "./Components/User_panel/Products/Products";
import AboutUs from "./Components/User_panel/AboutUs/AboutUs";
import ContactUs from "./Components/User_panel/ContactUs/ContactUs";
import Infrastructor from "./Components/User_panel/Infrastructor/Infrastructor";
import Testimonials from "./Components/User_panel/Testimonials/Testimonials";
import { Routes, Route } from "react-router-dom";
import Layout from "./Components/User_panel/Layout";
import LayoutAdmin from "./Components/Admin_panel/Layout";
import Dashboard from "./Components/Admin_panel/Dashboard/Dashboard";
import PageText from "./Components/Admin_panel/Page_text/PageText";
import PageImage from "./Components/Admin_panel/Page_image/PageImage";
import Testimonials_admin from "./Components/Admin_panel/Testimonials/Testimonials";
import Products_admin from "./Components/Admin_panel/Products/Products";
import Product_item from "./Components/Admin_panel/Products/Product_item";
import Login from "./Components/User_panel/Login/Login";
import UserMessages from "./Components/Admin_panel/UserMessages/UserMessages";
import Infrastructure_Admin from "./Components/Admin_panel/Infrastructure/Infrastructure";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/products/*" element={<Products />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/infrastructure" element={<Infrastructor />} />
          <Route path="/testimonials" element={<Testimonials />} />
        </Route>
        <Route path="/admin">
          <Route path="login" element={<Login />} />
          <Route path="" element={<LayoutAdmin />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="page_text" element={<PageText />} />
            <Route path="page_image" element={<PageImage />} />
            <Route path="testimonials" element={<Testimonials_admin />} />
            <Route path="product_category" element={<Products_admin />} />
            <Route path="product_items" element={<Product_item />} />
            <Route path="user_messages" element={<UserMessages />} />
            <Route path="infrastructure" element={<Infrastructure_Admin />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
