import axios from "axios";
import { Form } from "react-router-dom";
import config from "./config";
let api = {};
let rootUrl = config.backendUrl;

function getToken() {
  return localStorage.getItem("token");
}

const apiInstance = axios.create({
  baseURL: rootUrl, // Replace with your API's base URL
});

apiInstance.interceptors.request.use(
  (config) => {
    // You can modify the config here, or just return it as is
    config.headers.auth = getToken();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses here
    if (response.data.error && response.data.status == 401) {
      localStorage.removeItem("token");
      window.location = "/admin/login";
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.getPages = async () => {
  try {
    let result = await apiInstance.get(`${rootUrl}/get_pages`);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getPageText = async (page_id) => {
  try {
    let result = await axios.post(`${rootUrl}/get_page_text`, {
      pageId: page_id,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getTestimonials = async () => {
  try {
    let result = await axios.get(`${rootUrl}/get_testimonials`);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.addQuote = async (name, email, product, phone, location, message) => {
  try {
    let result = await axios.post(`${rootUrl}/add_quote`, {
      name,
      email,
      product,
      phone,
      location,
      message,
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};

api.getPageImages = async (page_id) => {
  try {
    let result = await axios.post(`${rootUrl}/get_page_images`, {
      pageId: page_id,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.updateText = async (textId, content) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/update_text`, {
      textId,
      content,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.updateImage = async (id, file) => {
  try {
    let formData = new FormData();
    formData.append(`${id}`, file);
    let result = await apiInstance.post(`${rootUrl}/update_image`, formData);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.addImage = async (id, file) => {
  try {
    let formData = new FormData();
    formData.append(`${id}`, file);
    let result = await apiInstance.post(`${rootUrl}/add_page_image`, formData);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.addTestimonials = async (name, location, content) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/add_testimonial`, {
      name,
      location,
      content,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.updateTestimonial = async (id, name, location, content) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/update_testimonial`, {
      id,
      name,
      location,
      content,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.deleteTestimonials = async (id) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/delete_testimonial`, {
      id,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.addProductCategory = async (name, description, file) => {
  try {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", file);
    let result = await apiInstance.post(
      `${rootUrl}/add_product_category`,
      formData
    );
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.updateProductCategory = async (id, name, description, file) => {
  try {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", file);
    let result = await apiInstance.post(
      `${rootUrl}/update_product_category`,
      formData
    );
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.addMoreProductImages = async (id, files = []) => {
  try {
    let formData = new FormData();
    formData.append("id", id);
    for (let i = 0; i < files.length; i++) {
      formData.append("image", files[i]);
    }

    let result = await apiInstance.post(
      `${rootUrl}/add_product_item_images`,
      formData
    );
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.deleteProductImage = async (id) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/delete_product_image`, {
      id,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getProductCategories = async () => {
  try {
    let result = await axios.get(`${rootUrl}/get_product_categories`);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getInfrastructureImages = async () => {
  try {
    let result = await axios.get(`${rootUrl}/get_infrastructure_images`);
    return result.data;
  } catch (err) {
    throw err;
  }
};

api.deleteProductCategory = async (id) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/delete_category`, {
      id,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getProductItems = async (categoryId) => {
  try {
    let result = await axios.post(`${rootUrl}/get_products`, {
      categoryId,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.deleteProductItem = async (id) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/delete_item`, {
      id,
    });
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.deleteImage = async (id) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/delete_image`, {
      id,
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};

api.addProductItem = async (id, name, description, file) => {
  try {
    let formData = new FormData();
    formData.append("category_id", id);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", file);
    let result = await apiInstance.post(
      `${rootUrl}/add_product_item`,
      formData
    );
    if (result?.success) {
      return result.data.data;
    } else {
      new Error("Something went wrong");
    }
  } catch (err) {
    throw err;
  }
};

api.updateProductItem = async (id, name, description, file) => {
  try {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", file);
    let result = await apiInstance.post(
      `${rootUrl}/update_product_item`,
      formData
    );
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.login = async (username, password) => {
  try {
    let result = await apiInstance.post(`${rootUrl}/login`, {
      username,
      password,
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};

api.getAllPageText = async () => {
  try {
    let result = await axios.post(`${rootUrl}/get_all_text`);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getAllPageImages = async () => {
  try {
    let result = await axios.post(`${rootUrl}/get_all_images`);
    return result.data.data;
  } catch (err) {
    throw err;
  }
};

api.getQuotes = async () => {
  try {
    let result = await apiInstance.get(`${rootUrl}/get_quotes`);
    return result.data;
  } catch (err) {
    throw err;
  }
};
export default api;
