import React from "react";
import Styles from "./Sidebar.module.css";
import product_icon from "../../../assets/product_icon.svg";
import user_icon from "../../../assets/user_icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import text_icon from "../../../assets/text_icon.svg";
import image_icon from "../../../assets/image_icon.svg";
import testimonial_icon from "../../../assets/testimonials.svg";
import message_icon from "../../../assets/message_icon.svg";
import infraStructure_icon from "../../../assets/infraStructure_icon.svg";
function Sidebar(props) {
  let navigate = useNavigate();
  let location = useLocation();

  return (
    <div className={Styles.container}>
      <div className={Styles.userInfo}>
        <img src={user_icon} alt="dp" className={Styles.user_icon} />
        <p>Hello, Admin</p>
      </div>

      {/* <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/dashboard");
        }}
      >
        <p>dashboard</p>
        <img src={dashboard_icon} className={Styles.sidebarIcon} alt="icon" />
      </div> */}
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/page_text");
        }}
      >
        <p>Page texts</p>
        <img src={text_icon} className={Styles.sidebarIcon} alt="icon" />
      </div>
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/page_image");
        }}
      >
        <p>Page Images</p>
        <img src={image_icon} className={Styles.sidebarIcon} alt="icon" />
      </div>
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/testimonials");
        }}
      >
        <p>Testimonials</p>
        <img src={testimonial_icon} className={Styles.sidebarIcon} alt="icon" />
      </div>
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/product_category");
        }}
      >
        <p>Product Category</p>
        <img src={product_icon} className={Styles.sidebarIcon} alt="icon" />
      </div>
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/product_items");
        }}
      >
        <p>Product Items</p>
        <img src={product_icon} className={Styles.sidebarIcon} alt="icon" />
      </div>
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/infrastructure");
        }}
      >
        <p>Infrastructure</p>
        <img
          src={infraStructure_icon}
          className={Styles.sidebarIcon}
          alt="icon"
        />
      </div>
      <div
        className={Styles.sidebar_item}
        onClick={() => {
          navigate("/admin/user_messages");
        }}
      >
        <p>User Messages</p>
        <img src={message_icon} className={Styles.sidebarIcon} alt="icon" />
      </div>
    </div>
  );
}

export default Sidebar;
