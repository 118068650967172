import React, { useEffect, useState } from "react";
import Styles from "./PageImage.module.css";
import pageIcon from "../../../assets/page1_icon.svg";
import Table from "../Table/Table";
import api from "../../../Api";
import AlertBox from "../../AlertBox/AlertBox";
function PageImage(props) {
  let [pages, changePagesData] = useState([]);
  let [pageText, changePageText] = useState([]);
  let [currentPageId, changeCurrentPageId] = useState(null);
  let [alertActive, changeActive] = useState({
    type: "",
    message: "",
  });

  async function getPages() {
    try {
      let pageData = await api.getPages();
      changePagesData(pageData);
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  useEffect(() => {
    getPages();
  }, []);

  async function fetchPageData(id) {
    try {
      if (id) {
        let result = await api.getPageImages(id);
        changePageText(result);
      }
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  function hideAlert() {
    changeActive({
      type: "",
      message: "",
    });
  }

  function showAlert(type, message) {
    changeActive({
      type,
      message,
    });
  }
  return (
    <div className={Styles.container}>
      <AlertBox
        message={alertActive.message}
        type={alertActive.type}
        isActive={alertActive.message}
        changeActive={hideAlert}
      />
      <div className={Styles.headingBox}>
        <img src={pageIcon} alt="page" />
        <p className={Styles.heading}>page update(image)</p>
      </div>
      <div className={Styles.selectBox}>
        <p>Select Page:</p>
        <select
          onChange={(e) => {
            changeCurrentPageId(e.target.value);
            fetchPageData(e.target.value);
          }}
        >
          <option value="">Select</option>
          {pages &&
            pages.map((val, id) => {
              return (
                <option value={val.id} key={id}>
                  {val.page_name}
                </option>
              );
            })}
        </select>
      </div>
      <div className={Styles.updateSection}>
        <Table
          data={pageText}
          type={"image"}
          currentPageId={currentPageId}
          fetchPageData={fetchPageData}
          showAlert={showAlert}
        />
      </div>
    </div>
  );
}

export default PageImage;
