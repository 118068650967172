import React, { useContext, useEffect, useState } from "react";
import Styles from "./Home.module.css";
import classnames from "classnames";
import badge from "../../../assets/badge.svg";
import support from "../../../assets/support.svg";
import qualityIcon from "../../../assets/quality_icon.svg";
import satisfied_icon from "../../../assets/satisfied_icon.svg";
import config from "../../../config";
import DataContext from "../DataContext";
import utils from "../../../utils";
import { useNavigate } from "react-router-dom";
function Home(props) {
  let navigate = useNavigate();
  let dataContext = useContext(DataContext);
  let products = dataContext.productCategories ?? [];
  let infoDetails = [
    { info: "Satisfaction Guarantee", image: satisfied_icon },
    { info: "Quality Craftsmanship", image: qualityIcon },
    { info: "24/7 customer support", image: support },
    { info: "stylish & quality conneciton", image: badge },
  ];
  return (
    <div className={Styles.container}>
      <div
        className={Styles.section1}
        style={{
          backgroundImage: `url(${
            config.backendUrl
          }/images/${utils.getImageUsingName(
            dataContext?.pageImages,
            "section_1_bg"
          )})`,
        }}
      >
        <p className={Styles.heading}>
          {dataContext.pageText && dataContext.pageText[0]?.content}
        </p>
        <button
          className={Styles.learnMoreButton}
          onClick={() => {
            navigate("/about");
          }}
        >
          learn more
        </button>
      </div>
      <div
        className={Styles.section3}
        style={{ margin: 0, padding: "3em 0em" }}
      >
        <div className={Styles.headingSection}>
          <p className={Styles.heading2} style={{ fontStyle: "italic" }}>
            {dataContext.pageText && dataContext.pageText[1]?.content}
          </p>
          <p className={Styles.smallHeading}>
            {dataContext.pageText && dataContext.pageText[2]?.content}
          </p>
        </div>
      </div>
      <div className={classnames(Styles.section2, Styles.layoutBox)}>
        <p className={Styles.mainHeading}>product categories</p>
        <div className={Styles.productContainer}>
          {products.map((val, index) => {
            return (
              <div className={Styles.productBox}>
                <p>{val?.name}</p>
                <img
                  src={`${config.backendUrl}/images/${val?.image}`}
                  alt="granite"
                />
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/products?id=${val?.id}`);
                  }}
                >
                  Discover
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Styles.section3}>
        <div className={classnames(Styles.section3Content, Styles.layoutBox)}>
          <img
            src={`${config.backendUrl}/images/${utils.getImageUsingName(
              dataContext?.pageImages,
              "about_section"
            )}`}
            alt="img"
          />
          <div className={Styles.contentBox}>
            <p className={Styles.mainHeading}>about us</p>
            <p className={Styles.contentText}>
              {dataContext.pageText && dataContext.pageText[4]?.content}
            </p>
            <button
              className={Styles.button2}
              onClick={() => {
                navigate("/contact");
              }}
            >
              get in touch
            </button>
          </div>
        </div>
      </div>
      <div className={classnames(Styles.section4, Styles.layoutBox)}>
        <div className={Styles.infoContainer}>
          {infoDetails.map((val, index) => {
            return (
              <div className={Styles.infoBox} key={index}>
                <img src={val.image} alt="img" />
                <p>{val.info}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Home;
