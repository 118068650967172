import React, { useState, useContext } from "react";
import Styles from "./Header.module.css";
import logo from "../../../assets/logo.png";
import menuIcon from "../../../assets/menuIcon.svg";
import crossIcon from "../../../assets/cross_icon.svg";
import companyIcon from "../../../assets/company_icon.svg";
import homeIcon from "../../../assets/home_icon.svg";
import arrow_icon from "../../../assets/arrow_icon.svg";
import productsIcon from "../../../assets/products_icon.svg";
import Dropdown from "../Dropdown/Dropdown";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import config from "../../../config";
import DataContext from "../DataContext";
function Header(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let dataContext = useContext(DataContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  let [isSidebarOpen, changeSidebar] = useState(false);
  let [dropdownState, updateDropdown] = useState({
    id: null,
  });
  let navLinks = [
    {
      name: "home",
      link: "/",
      icon: homeIcon,
    },
    {
      name: "company",
      icon: companyIcon,
      subLinks: [
        { name: "about", link: "/about" },
        { name: "contact", link: "/contact" },
        { name: "infrastructure", link: "/infrastructure" },
        { name: "testimonials", link: "/testimonials" },
      ],
    },
    {
      name: "products",
      icon: productsIcon,
      subLinks:
        dataContext?.productCategories &&
        dataContext.productCategories.map((val, id) => {
          return { name: val.name, link: `/products?id=${val.id}` };
        }),
    },
  ];

  function updateDropdownState() {
    updateDropdown({ id: null });
  }

  return (
    <header style={{ width: "100%", position: "relative" }}>
      <div
        className={Styles.container}
        style={{
          position: location.pathname == "/" ? "absolute" : "relative",
          backgroundColor: location.pathname == "/" ? "transparent" : "white",
        }}
      >
        <div className={Styles.main}>
          <img
            src={logo}
            alt="logo"
            className={Styles.logo}
            onClick={() => {
              navigate("/");
            }}
          />

          {isTabletOrMobile ? (
            <div>
              <img
                src={menuIcon}
                alt="menu"
                className={Styles.menuIcon}
                onClick={() => {
                  changeSidebar(true);
                }}
              />
              <div
                className={Styles.mobileMenu}
                style={{ marginRight: isSidebarOpen ? "0px" : "-500px" }}
              >
                <img
                  src={crossIcon}
                  alt="cross"
                  onClick={() => {
                    changeSidebar(false);
                  }}
                />
                {navLinks.map((val, index) => {
                  return val.link ? (
                    <div className={Styles.mobileMenuItem}>
                      <img src={val.icon} alt="icon" />
                      <p
                        style={{
                          cursor: "pointer",
                          color: "black",
                        }}
                        onClick={() => {
                          navigate(val.link);
                          changeSidebar(false);
                        }}
                      >
                        {val.name}
                      </p>
                    </div>
                  ) : (
                    <div id={val.name} className={Styles.mobileMenuBox}>
                      <div
                        className={Styles.mobileMenuItem}
                        onClick={() => {
                          if (dropdownState.id == val.name) {
                            updateDropdown({ id: null });
                          } else {
                            updateDropdown({ id: val.name });
                          }
                        }}
                      >
                        <img src={val.icon} alt="icon" />
                        <p
                          style={{
                            color: "black",
                          }}
                        >
                          {val.name}
                        </p>
                        <img
                          src={arrow_icon}
                          alt="arr"
                          style={{ width: "1em" }}
                        />
                      </div>

                      {dropdownState.id == val.name ? (
                        <div className={Styles.mobileMenuDropdown}>
                          {val.subLinks.map((val, index) => {
                            return (
                              <Link
                                to={val.link}
                                className={Styles.subLinks}
                                onClick={() => {
                                  changeSidebar(false);
                                }}
                              >
                                {val.name}
                              </Link>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <Dropdown
                      id={val.name}
                      updateDropdownState={updateDropdownState}
                      isActive={dropdownState.id == val.name}
                    >
                      {val.subLinks.map((val, index) => {
                        return (
                          <Link to={val.link} className={Styles.subLinks}>
                            {val.name}
                          </Link>
                        );
                      })}
                    </Dropdown> */}
                    </div>
                  );
                })}
                {/* <div className={Styles.mobileMenuItem}>
                  <p
                    style={{
                      cursor: "pointer",
                      color: "black",
                    }}
                    onClick={() => {
                      props.changeActive(true);
                    }}
                  >
                    Get Quote
                  </p>
                </div> */}
                <button
                  className={Styles.quoteButton}
                  style={{
                    color: isTabletOrMobile
                      ? "white"
                      : location.pathname == "/"
                      ? "white"
                      : "black",
                    borderColor: location.pathname == "/" ? "white" : "black",
                  }}
                  onClick={() => {
                    changeSidebar(false);
                    props.changeActive(true);
                  }}
                >
                  get quote
                </button>
              </div>
            </div>
          ) : (
            <div className={Styles.headerNav}>
              {navLinks.map((val, index) => {
                return val.link ? (
                  <p
                    style={{
                      cursor: "pointer",
                      color: location.pathname == "/" ? "white" : "black",
                    }}
                    onClick={() => {
                      navigate(val.link);
                    }}
                  >
                    {val.name}
                  </p>
                ) : (
                  <div id={val.name}>
                    <p
                      style={{
                        cursor: "pointer",
                        color: location.pathname == "/" ? "white" : "black",
                      }}
                      onClick={() => {
                        updateDropdown({ id: val.name });
                      }}
                    >
                      {val.name}
                    </p>
                    <Dropdown
                      id={val.name}
                      updateDropdownState={updateDropdownState}
                      isActive={dropdownState.id == val.name}
                    >
                      {val.subLinks.map((val, index) => {
                        return (
                          <Link
                            to={val.link}
                            className={Styles.subLinks}
                            onClick={() => {
                              updateDropdownState();
                            }}
                          >
                            {val.name}
                          </Link>
                        );
                      })}
                    </Dropdown>
                  </div>
                );
              })}
              <button
                className={Styles.quoteButton}
                style={{
                  color: location.pathname == "/" ? "white" : "black",
                  borderColor: location.pathname == "/" ? "white" : "black",
                }}
                onClick={() => {
                  props.changeActive(true);
                }}
              >
                get quote
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
