import React from "react";
import Styles from "./CommonHeader.module.css";

function CommonHeader(props) {
  return (
    <div
      className={Styles.header}
      style={{
        background: `url(${encodeURI(props.bg)})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <p>{props.heading}</p>
    </div>
  );
}

export default CommonHeader;
