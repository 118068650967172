import React, { useState, useEffect } from "react";
import Styles from "./Products.module.css";
import api from "../../../Api";
import AlertBox from "../../AlertBox/AlertBox";
import product_icon from "../../../assets/product_icon.svg";
import Table from "../Table/Table";
function Product_item(props) {
  let [productItems, changeProductItems] = useState([]);
  let [productCategories, changeProductCategories] = useState([]);
  let [currentProductId, changeCurrentProductId] = useState(null);
  let [alertActive, changeActive] = useState({
    type: "",
    message: "",
  });

  async function getProducts() {
    try {
      let productData = await api.getProductCategories();
      changeProductCategories(productData);
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  async function fetchPageData(id) {
    try {
      let result = await api.getProductItems(id);
      changeProductItems(result);
    } catch (err) {
      showAlert("danger", "Something went wrong");
    }
  }

  function hideAlert() {
    changeActive({
      type: "",
      message: "",
    });
  }

  function showAlert(type, message) {
    changeActive({
      type,
      message,
    });
  }
  return (
    <div className={Styles.container}>
      <AlertBox
        message={alertActive.message}
        type={alertActive.type}
        isActive={alertActive.message}
        changeActive={hideAlert}
      />
      <div className={Styles.headingBox}>
        <img src={product_icon} alt="page" />
        <p className={Styles.heading}>Products</p>
      </div>
      <div className={Styles.selectBox}>
        <p>Select Category:</p>
        <select
          onChange={(e) => {
            if (e.target.value) {
              changeCurrentProductId(e.target.value);
              fetchPageData(e.target.value);
            }
          }}
        >
          <option value="">Select</option>
          {productCategories &&
            productCategories.map((val, id) => {
              return (
                <option value={val.id} key={id}>
                  {val.name}
                </option>
              );
            })}
        </select>
      </div>
      <div className={Styles.updateSection}>
        <Table
          data={productItems}
          type={"product_items"}
          currentProductId={currentProductId}
          fetchPageData={fetchPageData}
          showAlert={showAlert}
        />
      </div>
    </div>
  );
}

export default Product_item;
