import React, { useEffect, useState } from "react";
import Styles from "./Table.module.css";
import Modal from "../../User_panel/Modal/Modal";
import api from "../../../Api";
import delete_icon from "../../../assets/delete_icon.svg";
import config from "../../../config";
function Table(props) {
  let [isModalActive, changeModalState] = useState(false);
  let [currentId, changeCurrentId] = useState(null);
  let [updateTestimonial, changeUpdateTestimonial] = useState(null);
  let [deleteTestimonial, changeDeleteTestimonial] = useState(null);
  let [updateCategory, changeUpdateCategory] = useState(null);
  let [deleteCategory, changeDeleteCategory] = useState(null);
  let [deleteInfraImage, changeDeleteInfraImage] = useState(null);
  let [categoryFile, changeCategoryFile] = useState(null);
  let [deleteItem, changeDeleteItem] = useState(null);
  let [updateItem, changeUpdateItem] = useState(null);
  let [itemFile, changeItemFile] = useState(null);
  let [fileEvent, changeFileEvent] = useState(null);
  useEffect(() => {
    // if (props.type == "product_items" && isModalActive) {
    //   clearValues();
    // }
  }, [isModalActive]);

  async function handleTextUpdate(currentId, text) {
    try {
      let result = await api.updateText(currentId, text);
      document.getElementById("changeText").value = "";
      props.fetchPageData(props.currentPageId);
      changeModalState(false);
      props.showAlert("success", "Successfully Updated");
    } catch (err) {
      props.showAlert("danger", "Cannot update, something went wrong");
    }
  }

  async function handleFileUpload(e) {
    changeFileEvent(e);
  }

  async function uploadFile() {
    try {
      let file = fileEvent?.target?.files[0];
      if (!file) {
        throw "error";
      }

      if (props.type == "infrastructure") {
        let result = await api.addImage(props.currentPageId, file);
        props.fetchPageData(props.currentPageId);
        changeModalState(false);
        fileEvent.target.value = "";
        changeFileEvent(null);
        props.showAlert("success", "Successfully Updated");
      } else {
        let result = await api.updateImage(currentId, file);
        props.fetchPageData(props.currentPageId);
        changeModalState(false);
        fileEvent.target.value = "";
        changeFileEvent(null);
        props.showAlert("success", "Successfully Updated");
      }
    } catch (err) {
      changeModalState(false);
      fileEvent.target.value = "";
      changeFileEvent(null);
      props.showAlert("danger", "Cannot update, something went wrong");
    }
  }
  function handleFiles(e, type = null) {
    if (props.type == "product") {
      let file = e.target.files[0];
      changeCategoryFile(file);
    }
    if (props.type == "product_items") {
      if (type == "addImage") {
        changeItemFile(e.target.files);
      } else {
        let file = e.target.files[0];
        changeItemFile(file);
      }
    }
  }

  async function addNewTestimonial() {
    try {
      let name = document.getElementById("userName").value;
      let location = document.getElementById("userLocation").value;
      let content = document.getElementById("userContent").value;
      if (name && location && content) {
        let result = await api.addTestimonials(name, location, content);
        changeModalState(false);
        props.fetchPageData();
        props.showAlert("success", "Successfully Added");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      console.log(err);
      props.showAlert("danger", "Cannot add, something went wrong");
    }
  }
  async function updateTestimonialHandler() {
    try {
      let name = updateTestimonial?.name;
      let content = updateTestimonial?.content;
      let location = updateTestimonial?.location;
      let id = updateTestimonial?.id;

      let changed_name = document.getElementById("userName").value;
      let changed_location = document.getElementById("userLocation").value;
      let changed_content = document.getElementById("userContent").value;

      if (
        name != changed_name ||
        content != changed_content ||
        location != changed_location
      ) {
        let result = await api.updateTestimonial(
          id,
          changed_name ? changed_name : name,
          changed_location ? changed_location : location,
          changed_content ? changed_content : content
        );
        changeModalState(false);
        changeUpdateTestimonial(null);
        props.fetchPageData();
        props.showAlert("success", "Successfully Updated");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot update, something went wrong");
    }
  }

  async function deleteTestimonialHandler() {
    try {
      let id = deleteTestimonial?.id;
      if (id) {
        let result = await api.deleteTestimonials(id);
        changeModalState(false);
        changeDeleteTestimonial(null);
        props.fetchPageData();

        props.showAlert("success", "Successfully Deleted");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot Delete, something went wrong");
    }
  }

  async function addNewCategory() {
    try {
      let name = document.getElementById("categoryName").value;
      let location = document.getElementById("categoryDescription").value;
      let file = categoryFile;

      if (name && location && file) {
        let result = await api.addProductCategory(name, location, file);
        changeModalState(false);
        props.fetchPageData();
        changeCategoryFile(null);
        props.showAlert("success", "Successfully Added");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      console.log(err);
      props.showAlert("danger", "Cannot add, something went wrong");
    }
  }
  async function addNewProduct() {
    try {
      let name = document.getElementById("productName").value;
      let location = document.getElementById("productDescription").value;
      let file = itemFile;

      if (name && location && file) {
        let result = await api.addProductItem(
          props.currentProductId,
          name,
          location,
          file
        );
        changeModalState(false);
        props.fetchPageData(props.currentProductId);
        changeItemFile(null);
        props.showAlert("success", "Successfully Added");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      console.log(err);
      props.showAlert("danger", "Cannot add, something went wrong");
    }
  }

  async function updateCategoryHandler() {
    try {
      let name = updateCategory?.name;
      let description = updateCategory?.description;
      let file = categoryFile;
      let id = updateCategory?.id;

      let changed_name = document.getElementById("categoryName").value;
      let changed_description = document.getElementById(
        "categoryDescription"
      ).value;

      if (name != changed_name || description != changed_description || file) {
        let result = await api.updateProductCategory(
          id,
          changed_name ? changed_name : name,
          changed_description ? changed_description : description,
          file
        );
        changeModalState(false);
        changeUpdateCategory(null);
        props.fetchPageData();
        props.showAlert("success", "Successfully Updated");
      } else {
        props.showAlert("danger", "Nothing Changed");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot update, something went wrong");
    }
  }

  async function updateProductHandler() {
    try {
      let name = updateItem?.name;
      let description = updateItem?.description;
      let file = itemFile;
      let id = updateItem?.id;

      let changed_name = document.getElementById("productName").value;
      let changed_description =
        document.getElementById("productDescription").value;

      if (name != changed_name || description != changed_description || file) {
        let result = await api.updateProductItem(
          id,
          changed_name ? changed_name : name,
          changed_description ? changed_description : description,
          file
        );
        changeModalState(false);
        changeUpdateItem(null);
        props.fetchPageData(props.currentProductId);
        props.showAlert("success", "Successfully Updated");
      } else {
        props.showAlert("danger", "Nothing Changed");
      }
    } catch (err) {
      console.log(err);
      props.showAlert("danger", "Cannot update, something went wrong");
    }
  }

  async function deleteCategoryHandler() {
    try {
      let id = deleteCategory?.id;
      if (id) {
        let result = await api.deleteProductCategory(id);
        changeModalState(false);
        changeDeleteCategory(null);
        props.fetchPageData();

        props.showAlert("success", "Successfully Deleted");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot Delete, something went wrong");
    }
  }

  async function deleteInfraImageHandler() {
    try {
      let id = deleteInfraImage?.id;
      if (id) {
        let result = await api.deleteImage(id);
        changeModalState(false);
        changeDeleteInfraImage(null);
        props.fetchPageData();
        props.showAlert("success", "Successfully Deleted");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot Delete, something went wrong");
    }
  }

  async function deleteProductItem() {
    try {
      let id = deleteItem?.id;
      if (id) {
        let result = await api.deleteProductItem(id);
        changeModalState(false);
        changeDeleteItem(null);
        props.fetchPageData(props.currentProductId);

        props.showAlert("success", "Successfully Deleted");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot Delete, something went wrong");
    }
  }

  function clearValues(type = null) {
    if (props.type == "testimonials") {
      document.getElementById("userName").value = "";
      document.getElementById("userLocation").value = "";
      document.getElementById("userContent").value = "";
    } else if (props.type == "product") {
      document.getElementById("categoryName").value = "";
      document.getElementById("categoryDescription").value = "";
      document.getElementById("categoryImage").value = "";
    } else if (props.type == "product_items") {
      if (type == "addImages") {
        document.getElementById("productImage").value = "";
      } else {
        document.getElementById("productName").value = "";
        document.getElementById("productDescription").value = "";
        document.getElementById("productImage").value = "";
      }
    }
  }

  async function addMoreProductImages() {
    try {
      let files = itemFile;
      console.log(files);
      console.log(updateItem);
      if (files && updateItem) {
        let result = await api.addMoreProductImages(updateItem?.id, files);
        changeModalState(false);
        props.fetchPageData(props.currentProductId);
        changeItemFile(null);
        props.showAlert("success", "Successfully Added");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      console.log(err);
      props.showAlert("danger", "Cannot add, something went wrong");
    }
  }
  function getModalUi(type) {
    switch (type) {
      case "text":
        return (
          <div className={Styles.changeModalContainer}>
            <p>Enter updated text</p>
            <textarea rows={10} id="changeText"></textarea>
            <button
              onClick={() => {
                if (currentId) {
                  let text = document.getElementById("changeText").value;
                  handleTextUpdate(currentId, text);
                }
              }}
            >
              Update
            </button>
          </div>
        );
      case "image":
        return (
          <div className={Styles.changeModalContainer}>
            <p>Upload image</p>
            <input type="file" onChange={handleFileUpload} />
            <button
              onClick={() => {
                uploadFile();
              }}
            >
              Upload
            </button>
          </div>
        );
      case "infrastructure":
        return deleteInfraImage ? (
          <div className={Styles.changeModalContainer}>
            <p>Are your sure?</p>
            <button
              onClick={() => {
                deleteInfraImageHandler();
              }}
            >
              Delete
            </button>
            <button
              style={{ marginTop: "0.5em" }}
              onClick={() => {
                changeDeleteInfraImage(null);
                changeModalState(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className={Styles.changeModalContainer}>
            <p>Upload image</p>
            <input type="file" onChange={handleFileUpload} />
            <button
              onClick={() => {
                uploadFile();
              }}
            >
              Upload
            </button>
          </div>
        );

      case "testimonials":
        return deleteTestimonial ? (
          <div className={Styles.changeModalContainer}>
            <p>Are your sure?</p>
            <button
              onClick={() => {
                deleteTestimonialHandler();
              }}
            >
              Delete
            </button>
            <button
              style={{ marginTop: "0.5em" }}
              onClick={() => {
                changeDeleteTestimonial(null);
                changeModalState(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className={Styles.changeModalContainer}>
            <p>Enter {updateTestimonial ? "new" : ""} name</p>
            <input
              type="text"
              id="userName"
              defaultValue={updateTestimonial ? updateTestimonial?.name : ""}
            />
            <p>Enter {updateTestimonial ? "new" : ""} Location</p>
            <input
              type="text"
              id="userLocation"
              defaultValue={
                updateTestimonial ? updateTestimonial?.location : ""
              }
            />
            <p>Enter {updateTestimonial ? "new" : ""} Content</p>
            <input
              type="text"
              id="userContent"
              defaultValue={updateTestimonial ? updateTestimonial?.content : ""}
            />
            <button
              onClick={() => {
                if (updateTestimonial) {
                  updateTestimonialHandler();
                } else {
                  addNewTestimonial();
                }
              }}
            >
              {updateTestimonial ? "Update" : "Add"}
            </button>
          </div>
        );
      case "product":
        return deleteCategory ? (
          <div className={Styles.changeModalContainer}>
            <p>Are your sure?</p>
            <button
              onClick={() => {
                deleteCategoryHandler();
              }}
            >
              Delete
            </button>
            <button
              style={{ marginTop: "0.5em" }}
              onClick={() => {
                changeDeleteCategory(null);
                changeModalState(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className={Styles.changeModalContainer}>
            <p>Enter {updateCategory ? "new" : ""} Category</p>
            <input
              type="text"
              id="categoryName"
              defaultValue={updateCategory ? updateCategory?.name : ""}
            />
            <p>Enter {updateCategory ? "new" : ""} Description</p>
            <textarea
              id="categoryDescription"
              rows={10}
              defaultValue={updateCategory ? updateCategory?.description : ""}
              style={{ marginBottom: "0.5em" }}
            ></textarea>
            <p>Upload Image</p>
            <input type="file" id="categoryImage" onChange={handleFiles} />
            <button
              onClick={() => {
                if (updateCategory) {
                  updateCategoryHandler();
                } else {
                  addNewCategory();
                }
              }}
            >
              {updateCategory ? "Update" : "Add"}
            </button>
          </div>
        );
      case "product_items":
        return deleteItem ? (
          <div className={Styles.changeModalContainer}>
            <p>Are your sure?</p>
            <button
              onClick={() => {
                deleteProductItem();
              }}
            >
              Delete
            </button>
            <button
              style={{ marginTop: "0.5em" }}
              onClick={() => {
                changeDeleteCategory(null);
                changeModalState(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : updateItem?.type && updateItem?.type == "addImage" ? (
          <div className={Styles.changeModalContainer}>
            <p>Upload Image</p>
            <input
              type="file"
              id="productImage"
              onChange={(e) => {
                handleFiles(e, "addImage");
              }}
              multiple
            />
            <button
              onClick={() => {
                addMoreProductImages();
              }}
            >
              {"Upload"}
            </button>
          </div>
        ) : (
          <div className={Styles.changeModalContainer}>
            <p>Enter {updateItem ? "new" : ""} product name</p>
            <input
              type="text"
              id="productName"
              defaultValue={updateItem ? updateItem?.name : ""}
            />
            <p>Enter {updateItem ? "new" : ""} product description</p>
            <textarea
              id="productDescription"
              rows={10}
              defaultValue={updateItem ? updateItem?.description : ""}
              style={{ marginBottom: "0.5em" }}
            ></textarea>
            <p>Upload Image</p>
            <input type="file" id="productImage" onChange={handleFiles} />
            <button
              onClick={() => {
                if (updateItem) {
                  updateProductHandler();
                } else {
                  addNewProduct();
                }
              }}
            >
              {updateItem ? "Update" : "Add"}
            </button>
          </div>
        );
      default:
        <p></p>;
    }
  }

  function redirectHandler(url) {
    window.open(url, "__blank");
  }

  async function deleteProductImage(id) {
    try {
      if (id) {
        let result = await api.deleteProductImage(id);
        props.fetchPageData(props.currentProductId);

        props.showAlert("success", "Successfully Deleted");
      } else {
        props.showAlert("danger", "Invalid Values");
      }
    } catch (err) {
      props.showAlert("danger", "Cannot Delete, something went wrong");
    }
  }

  function getBody(val) {
    switch (props.type) {
      case "text":
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>{val.type}</p>
            <p className={Styles.rowText}>{val.content}</p>
            <p className={Styles.rowText}>
              <button
                className={Styles.updateButton}
                onClick={() => {
                  changeCurrentId(val.id);
                  changeModalState(true);
                }}
              >
                Update
              </button>
            </p>
          </div>
        );
      case "image":
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>{val.type}</p>
            <p className={Styles.rowText}>
              <img
                src={`${config.backendUrl}/images/${val.image}`}
                alt="img"
                onClick={() => {
                  redirectHandler(`${config.backendUrl}/images/${val.image}`);
                }}
              />
              {/* <a
                href={`${config.backendUrl}/images/${val.image}`}
                target="__blank"
              >
                {val.image}
              </a> */}
            </p>
            <p className={Styles.rowText}>
              <button
                className={Styles.updateButton}
                onClick={() => {
                  changeCurrentId(val.id);
                  changeModalState(true);
                }}
              >
                Update
              </button>
            </p>
          </div>
        );
      case "testimonials":
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>{val.name}</p>
            <p className={Styles.rowText}>{val.location}</p>
            <p className={Styles.rowText}>{val.content}</p>
            <p className={Styles.rowText}>
              <button
                className={Styles.updateButton}
                onClick={() => {
                  clearValues();
                  changeUpdateTestimonial({
                    name: val.name,
                    location: val.location,
                    content: val.content,
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Update
              </button>
              <button
                style={{ marginTop: "0.5em" }}
                className={Styles.updateButton}
                onClick={() => {
                  changeDeleteTestimonial({
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Delete
              </button>
            </p>
          </div>
        );
      case "product":
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>{val.name}</p>
            <p className={Styles.rowText}>{val.description}</p>
            <p className={Styles.rowText}>
              {" "}
              <img
                src={`${config.backendUrl}/images/${val.image}`}
                alt="img"
                onClick={() => {
                  redirectHandler(`${config.backendUrl}/images/${val.image}`);
                }}
              />
              {/* <a
                href={`${config.backendUrl}/images/${val.image}`}
                target="__blank"
              >
                {val.image}
              </a> */}
            </p>
            <p className={Styles.rowText}>
              <button
                className={Styles.updateButton}
                onClick={() => {
                  clearValues();
                  changeUpdateCategory({
                    name: val.name,
                    description: val.description,
                    image: val.image,
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Update
              </button>
              <button
                style={{ marginTop: "0.5em" }}
                className={Styles.updateButton}
                onClick={() => {
                  changeDeleteCategory({
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Delete
              </button>
            </p>
          </div>
        );
      case "infrastructure":
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>
              {" "}
              <img
                src={`${config.backendUrl}/images/${val.image}`}
                alt="img"
                onClick={() => {
                  redirectHandler(`${config.backendUrl}/images/${val.image}`);
                }}
              />
            </p>
            <p className={Styles.rowText}>
              <button
                style={{ marginTop: "0.5em" }}
                className={Styles.updateButton}
                onClick={() => {
                  changeDeleteInfraImage({
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Delete
              </button>
            </p>
          </div>
        );
      case "product_items":
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>{val.product_name}</p>
            <p className={Styles.rowText}>{val.product_description}</p>
            <p className={Styles.rowText}>
              <img
                src={`${config.backendUrl}/images/${val.product_image}`}
                alt="img"
                onClick={() => {
                  redirectHandler(
                    `${config.backendUrl}/images/${val.product_image}`
                  );
                }}
              />
              {val.images &&
                val.images.map((imageOb, index) => {
                  return imageOb && imageOb?.image_id ? (
                    <div className={Styles.extraImageWrapper}>
                      <img
                        src={delete_icon}
                        alt="del"
                        className={Styles.deleteIcon}
                        onClick={() => {
                          deleteProductImage(imageOb.image_id);
                        }}
                      />
                      <img
                        className={Styles.extraProductImages}
                        style={{ marginLeft: "0.5em" }}
                        src={`${config.backendUrl}/images/${imageOb.image}`}
                        alt="img"
                        onClick={() => {
                          redirectHandler(
                            `${config.backendUrl}/images/${imageOb.image}`
                          );
                        }}
                      />
                    </div>
                  ) : null;
                })}
              {/* <a
                href={`${config.backendUrl}/images/${val.product_image}`}
                target="__blank"
              >
                {val.product_image}
              </a> */}
            </p>
            <p className={Styles.rowText}>
              <button
                className={Styles.updateButton}
                onClick={() => {
                  clearValues("addImages");
                  if (deleteItem) {
                    changeDeleteItem(null);
                  }
                  if (itemFile) {
                    changeItemFile(null);
                  }
                  changeUpdateItem({
                    name: val.product_name,
                    description: val.product_description,
                    image: val.product_image,
                    id: val.id,
                    type: "addImage",
                  });
                  changeModalState(true);
                }}
              >
                Add More images
              </button>
              <button
                style={{ marginTop: "0.5em" }}
                className={Styles.updateButton}
                onClick={() => {
                  clearValues();
                  if (deleteItem) {
                    changeDeleteItem(null);
                  }
                  if (itemFile) {
                    changeItemFile(null);
                  }
                  changeUpdateItem({
                    name: val.product_name,
                    description: val.product_description,
                    image: val.product_image,
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Update
              </button>
              <button
                style={{ marginTop: "0.5em" }}
                className={Styles.updateButton}
                onClick={() => {
                  changeDeleteItem({
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Delete
              </button>
            </p>
          </div>
        );
      case "userMessages":
        let date = new Date(val.date);
        return (
          <div
            className={Styles.row}
            style={{ gridTemplateColumns: getGridValues(props.type) }}
          >
            <p className={Styles.rowText}>{`${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`}</p>
            <p className={Styles.rowText}>{val.name}</p>
            <p className={Styles.rowText}>{val.email}</p>
            <p className={Styles.rowText}>{val.product}</p>
            <p className={Styles.rowText}>{val.phone}</p>
            <p className={Styles.rowText}>{val.question}</p>
            <p className={Styles.rowText}>{val.message}</p>
            {/* <p className={Styles.rowText}>
              <button
                className={Styles.updateButton}
                onClick={() => {
                  clearValues();
                  changeUpdateTestimonial({
                    name: val.name,
                    location: val.location,
                    content: val.content,
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Update
              </button>
              <button
                style={{ marginTop: "0.5em" }}
                className={Styles.updateButton}
                onClick={() => {
                  changeDeleteTestimonial({
                    id: val.id,
                  });
                  changeModalState(true);
                }}
              >
                Delete
              </button>
            </p> */}
          </div>
        );
      default:
        return {};
    }
  }

  function getHeadings(type) {
    switch (type) {
      case "text":
        return ["type", "text", ""];
      case "image":
        return ["type", "image", ""];
      case "testimonials":
        return ["name", "location", "content", ""];
      case "product":
        return ["category", "description", "image", ""];
      case "product_items":
        return ["Name", "description", "image", ""];
      case "userMessages":
        return [
          "Date",
          "Name",
          "Email",
          "Product",
          "Phone",
          "Question",
          "Message",
        ];
      case "infrastructure":
        return ["image", ""];
      default:
        return ["type", "text", ""];
    }
  }

  function getGridValues(type) {
    switch (type) {
      case "text":
        return "0.2fr 1fr 0.2fr";
      case "image":
        return "0.2fr 1fr 0.2fr";
      case "testimonials":
        return "0.2fr 0.2fr 1fr 0.2fr";
      case "product":
        return "0.2fr 1fr 0.3fr 0.2fr";
      case "product_items":
        return "0.2fr 1fr 0.3fr 0.2fr";
      case "userMessages":
        return "1fr 1fr 1fr 1fr 1fr 1fr 1fr";
      case "infrastructure":
        return "2fr 1fr";
      default:
        return "0.2fr 1fr 0.2fr";
    }
  }

  return (
    <div className={Styles.table}>
      {props.type == "testimonials" ||
      props.type == "product" ||
      props.type == "product_items" ||
      props.type == "infrastructure" ? (
        <div className={Styles.testimonialButton}>
          <button
            onClick={() => {
              if (props.type == "product") {
                changeCategoryFile(null);
                clearValues();
                changeModalState(true);
              } else if (props.type == "product_items") {
                clearValues();
                changeDeleteItem(null);
                changeUpdateItem(null);
                changeItemFile(null);
                changeModalState(true);
              } else if (props.type == "infrastructure") {
                clearValues();
                changeDeleteItem(null);
                changeUpdateItem(null);
                changeItemFile(null);
                changeModalState(true);
              } else {
                changeUpdateTestimonial(null);
                clearValues();
                changeModalState(true);
              }
            }}
          >
            Add More
          </button>
        </div>
      ) : (
        ""
      )}
      <Modal isActive={isModalActive} changeActive={changeModalState}>
        {getModalUi(props.type)}
      </Modal>
      <div
        className={Styles.row}
        style={{ gridTemplateColumns: getGridValues(props.type) }}
      >
        {getHeadings(props.type).map((val, index) => {
          return <p className={Styles.headColumn}>{val}</p>;
        })}
      </div>
      {props?.data.map((val, index) => {
        if (props.type == "infrastructure") {
          if (val.type == "infrastructure_main") return getBody(val);
        } else {
          return getBody(val);
        }
      })}
    </div>
  );
}

export default Table;
