import React, { useEffect, useRef } from "react";
import Styles from "./Dropdown.module.css";
import classnames from "classnames";
function Dropdown(props) {
  let dropdownRef = useRef(null);
  function eventListener(e) {
    let parentNode = e.target.parentNode;
    let clickIsOnDropdown = false;

    while (parentNode != null) {
      if (parentNode.id == props.id) {
        clickIsOnDropdown = true;
      }
      parentNode = parentNode.parentNode;
    }

    if (!clickIsOnDropdown && props.isActive) {
      props.updateDropdownState(false);
    }
  }

  function isDropdownAtEndOfScreen() {
    const dropdownElement = document.getElementById(props.id);
    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect();
      const windowHeight = window.innerWidth;

      // Compare the dropdown's bottom position with the window height
      if (dropdownRect.right + 200 >= windowHeight) {
        return { right: 0 };
      }
    }
  }

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener("click", eventListener);
    return () => {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("click", eventListener);
    };
  });
  return (
    <div
      ref={dropdownRef}
      className={classnames(
        Styles.container,
        props.isActive ? "" : Styles.hidden
      )}
      style={{ ...props.styles, ...isDropdownAtEndOfScreen() }}
    >
      {props.children}
    </div>
  );
}

export default Dropdown;
