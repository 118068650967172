import React, { useEffect } from "react";
import Styles from "./AlertBox.module.css";
import classnames from "classnames";
function AlertBox(props) {
  useEffect(() => {
    if (props.isActive) {
      setTimeout(() => {
        props.changeActive(false);
      }, 3000);
    }
  }, [props.isActive]);
  return (
    <div
      className={classnames(
        Styles.container,
        props.isActive ? Styles.active : "",
        props?.type == "danger" ? Styles.danger : Styles.success
      )}
    >
      <p>{props?.message}</p>
    </div>
  );
}

export default AlertBox;
