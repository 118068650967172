import React, { useEffect } from "react";
import Styles from "./Layout.module.css";
import Sidebar from "./Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import AlertContext from "./AlertContext.js";
import { useLocation } from "react-router-dom";
function Layout(props) {
  let location = useLocation();

  useEffect(() => {
    let token = localStorage.token;
    if (!token) {
      window.location = "/admin/login";
    }
  }, [location.pathname]);

  return (
    <AlertContext.Provider>
      <div className={Styles.container}>
        <div className={Styles.sidebar}>
          <Sidebar />
        </div>
        <div className={Styles.body}>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </AlertContext.Provider>
  );
}

export default Layout;
