import React, { useEffect, useState, useContext } from "react";
import Styles from "./Testimonials.module.css";
import CommonHeader from "../CommonHeader/CommonHeader";
import dummy from "../../../assets/dummy.png";
import api from "../../../Api";
import DataContext from "../DataContext";
import config from "../../../config";
import utils from "../../../utils";
function Testimonials(props) {
  let [data, changeData] = useState([]);
  let dataContext = useContext(DataContext);
  async function getData() {
    try {
      let result = await api.getTestimonials();
      changeData(result);
    } catch (err) {}
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className={Styles.container}>
      <CommonHeader
        heading={"Testimonials"}
        bg={`${config.backendUrl}/images/${utils.getImageUsingName(
          dataContext?.pageImages,
          "testimonial_header"
        )}`}
      />
      <div className={Styles.body}>
        <div className={Styles.infoContainer}>
          {data &&
            data.map((val, id) => {
              return (
                <div className={Styles.infoBox}>
                  <div className={Styles.infoTextBox}>
                    <p className={Styles.quote1}>“</p>
                    <p className={Styles.infoText}>{val?.content}</p>
                    <p className={Styles.quote2}>”</p>
                  </div>

                  <div className={Styles.userDesc}>
                    <p> {val?.name}</p>
                    <p> {val?.location}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
